import React, { useRef } from "react"
import styled, { ThemeProvider } from "styled-components"
import { rem } from "../../utils/styling"
import { MobileOnlyCarousel } from "src/components/Carousel"

import { mentorPointData } from "src/data/apprenticeData"

const Container = styled.div`
  padding: ${rem(10)}rem 0;
  position: relative;
  background-color: #fff;
  width: 100vw;
`

const Content = styled.div`
  background-color: #f4fafb;
  border: 1px solid #eeeeee;
  border-radius: ${rem(12)}rem;
  margin: ${rem(80)}rem 0;
  padding: ${rem(10)}rem ${rem(20)}rem;
  @media screen and (min-width: 1100px) {
    margin: ${rem(80)}rem ${rem(120)}rem;
    padding: ${rem(10)}rem ${rem(80)}rem;
  }
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${rem(24)}rem;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;
  font-family: Inter;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(34)}rem;
  }
`
const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${rem(20)}rem 0 ${rem(20)}rem;

  @media screen and (min-width: 1100px) {
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 550px) {
    margin: 0;
  }
  .slick-slide {
    padding: ${rem(10)}rem;
  }
`

const Card = styled.div`
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  padding: ${rem(50)}rem ${rem(15)}rem;
  margin: ${rem(30)}rem 0;

  @media screen and (min-width: 1100px) {
    width: ${rem(300)}rem;
    margin: 0 ${rem(30)}rem 0 0;
    padding: ${rem(50)}rem ${rem(0)}rem;
    &:last-child {
      margin: 0;
    }
  }
`

const CardIcon = styled.img`
  width: ${rem(50)}rem;
  height: ${rem(50)}rem;
  margin: 0 0 ${rem(15)}rem;
`

const CardText = styled.p`
  color: ${props => (props.color ? props.color : "#000")};
  line-height: ${rem(34)}rem;
  text-align: center;
  opacity: 0.7;
  padding: 0 ${rem(15)}rem;
  text-align: center;
  font-size: ${rem(16)}rem;
  font-weight: 500;
`

const CardTitle = styled.h3`
  font-weight: bold;
  font-size: ${rem(22)}rem;
  line-height: ${rem(34)}rem;
  text-align: center;
  color: #000000;
`

const MentorPoint = () => {
  return (
    <Container>
      <Content>
        <CardRow>
          <MobileOnlyCarousel>
            {mentorPointData.map((item, idx) => (
              <Card key={idx}>
                <CardIcon cardIndex={idx} src={item.image} />
                <CardTitle>{item.title}</CardTitle>
                <CardText>{item.text}</CardText>
              </Card>
            ))}
          </MobileOnlyCarousel>
        </CardRow>
      </Content>
    </Container>
  )
}

export default MentorPoint
