import React, { useRef } from "react"
import styled, { ThemeProvider } from "styled-components"
import { darkTheme } from "src/utils/themes"
import { ApprenticeshipHeader } from "src/components/Header"
import { rem } from "src/utils/styling"
import { PageSection } from "src/components/PageSection"
import Button from "src/components/Button"
import { HiChevronRight } from "react-icons/hi"
import Container from "src/components/Container"
import { LightFooter } from "src/components/Footer"
import {
  DEVICE_TYPE_DESKTOP,
  DEVICE_TYPE_MOBILE,
  useDeviceType,
} from "src/utils/deviceType"
import { ReactTypeformEmbed } from "react-typeform-embed"
import HowItWorksCard from "src/components/apprenticeship/howitworks-card"
import MentorBanner from "src/components/apprenticeship/mentor-banner"
import MentorPoint from "src/components/apprenticeship/mentor-points"
import Ask from "src/components/apprenticeship/ask"
import Apprentice from "src/components/apprenticeship/apprentice"

import { mentorHowItWorksData } from "src/data/apprenticeData"
import SEO from "src/components/seo"

const Section = styled(PageSection)`
  padding: ${rem(50)}rem 0;
  position: relative;
  background-color: ${props => (props.color ? props.color : "transparent")};
  @media screen and (min-width: 1100px) {
    padding: ${rem(10)}rem 0;
  }
  @media screen and (max-width: 1100px) {
    padding: ${rem(5)}rem 0;
  }
  @media screen and (max-width: 550px) {
    padding: ${rem(20)}rem 0;
  }
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${rem(24)}rem;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(34)}rem;
  }
`

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(100)}rem ${rem(140)}rem;
  background: linear-gradient(283.7deg, #03162c 28.07%, #4d5357 185.43%);
  border-radius: 20px;
  width: 90%;
  margin: 0 auto -${rem(180)}rem;
  @media screen and (max-width: 500px) {
    padding: 60px;
  }
  @media screen and (min-width: 1100px) {
    width: 894px;
  }
`

const HWSection = styled.div`
  width: 100%;
`
const HWTitle = styled.h3`
  background: linear-gradient(260.25deg, #f093fb -10.66%, #f5576c 113%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: ${rem(24)}rem;
  padding: ${rem(20)}rem 0;
  text-align: center;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(28)}rem;
  }
`

const HWTitleThree = styled(HWTitle)`
  background: linear-gradient(262.65deg, #005bea -27.25%, #00c6fb 124.05%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const HWText = styled.p`
  color: #000;
  font-weight: 300;
  text-align: center;
  font-size: 20px;
  line-height: 37px;
  opacity: 0.6;
  margin: 0 auto;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(20)}rem;
    width: 50%;
  }
`

const SeparatorContainer = styled.div`
  position: relative;
`
const MobileSeparatorContainer = styled(SeparatorContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${rem(10)}rem;
`

const MobileTimelineVerticalSeparator = styled.div`
  width: ${rem(2)}rem;
  background: linear-gradient(#fff, #aaa);
  height: ${rem(40)}rem;
`

const MobileSeparator = styled(MobileTimelineVerticalSeparator)`
  background: linear-gradient(#aaa, #fff);
  margin: ${rem(250)}rem 0 0;
  height: ${rem(40)}rem;
`

const HorizontalNavListSeparator = styled.li`
  padding: 0;
  margin: 0 ${rem(40)}rem;
  width: ${rem(3)}rem;
  height: ${rem(80)}rem;
  background: #fcb13f;
  display: block;
`

const TimelineVerticalSeparator = styled(HorizontalNavListSeparator)`
  height: ${rem(550)}rem;
  width: ${rem(2)}rem;
  background: linear-gradient(#fff, #aaa, #fff);
`

const TimelineVerticalSeparatorThree = styled(TimelineVerticalSeparator)`
  background: linear-gradient(#fff, #aaa);
  height: ${rem(60)}rem;
`

const HWTimeline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${rem(40)}rem 0;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
`

const TimelineBullet = styled.div`
  border: 2px solid #f5576c;
  background-color: #fff;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: ${rem(76)}rem;
  left: 39%;
`

const MobileTimelineBullet = styled(TimelineBullet)`
  left: calc(50% - 8px);
  top: ${rem(40)}rem;
`

const TimelineBulletThree = styled(TimelineBullet)`
  top: ${rem(60)}rem;
`

const CTAContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PageButton = styled(Button)`
  font-size: ${rem(18)}rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: 550px) {
    font-size: ${rem(15)}rem;
    padding: 0.4rem 1rem;
  }
  @media screen and (min-width: 1100px) {
    margin: 0;
  }
`

const Mentor = ({ location }) => {
  const deviceType = useDeviceType()
  const typeformRef = useRef()
  const onClickTypeform = () => {
    typeformRef.current && typeformRef.current.typeform.open()
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <SEO
        title="Nurture the next generation of developers"
        description="Mentor and help uplift talent from non-traditional backgrounds
            through personalized coaching, guiding them while they work on live
            projects with curated startups"
        location={location}
      />
      <ApprenticeshipHeader />
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        hideHeaders
        hideFooter
        url="https://jfox813621.typeform.com/to/ngMR8Nhk"
        ref={typeformRef}
      />
      <MentorBanner />
      <Section>
        <Container>
          <SectionTitle>How it Works</SectionTitle>
          {mentorHowItWorksData.map((item, idx) => (
            <HowItWorksCard
              key={`card-${idx}`}
              cardIndex={idx}
              title={item.title}
              text={item.text}
              timelineTitle={item.timelineTitle}
              tiimelineText={item.tiimelineText}
              desktopImage={item.desktopImage}
              desktopImage2={item.desktopImage2}
              mobileImage={item.mobileImage}
            />
          ))}
        </Container>
      </Section>
      <HWSection>
        <HWTitleThree>Value to you</HWTitleThree>
        <HWText>What does this mean to you as a Mentor?</HWText>
        {deviceType === DEVICE_TYPE_MOBILE && (
          <MobileSeparatorContainer>
            <MobileTimelineVerticalSeparator />
            <MobileTimelineBullet />
          </MobileSeparatorContainer>
        )}
        <HWTimeline>
          {deviceType === DEVICE_TYPE_DESKTOP && (
            <SeparatorContainer>
              <TimelineVerticalSeparatorThree />
              <TimelineBulletThree />
            </SeparatorContainer>
          )}
        </HWTimeline>
      </HWSection>
      <MentorPoint />
      <Ask />
      <Apprentice />
      <Section>
        <CTAContainer>
          <CTAContainerInner>
            <SectionTitle color={"#fff"} style={{ paddingBottom: 20 }}>
              Are you ready to engage <br /> our Apprentices?
            </SectionTitle>
            <div onClick={onClickTypeform}>
              <PageButton type="solid">
                Let’s go
                <HiChevronRight size={22} />
              </PageButton>
            </div>
          </CTAContainerInner>
        </CTAContainer>
      </Section>
      <LightFooter paddingTop={280} />
    </ThemeProvider>
  )
}

export default Mentor
