import React, { useRef } from "react"
import styled, { ThemeProvider } from "styled-components"
import { rem } from "../../utils/styling"
import { MobileOnlyCarousel } from "src/components/Carousel"

import { askData } from "src/data/apprenticeData"

const Container = styled.div`
  padding: ${rem(10)}rem 0;
  position: relative;
  background-color: #fff;
  width: 100vw;
`

const CommitmentContent = styled.div`
  background-color: #f4fafb;
  border: 1px solid #eeeeee;
  border-radius: ${rem(12)}rem;
  margin: ${rem(80)}rem 0;
  padding: ${rem(50)}rem ${rem(20)}rem;

  @media screen and (min-width: 1100px) {
    margin: ${rem(80)}rem auto;
    padding: ${rem(50)}rem ${rem(80)}rem;
    max-width: ${rem(1000)}rem;
  }
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${rem(24)}rem;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;
  font-family: Inter;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(34)}rem;
  }
`
const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${rem(60)}rem 0 ${rem(20)}rem;

  @media screen and (min-width: 1100px) {
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 550px) {
    margin: 0;
  }

  .slick-slide {
    padding: ${rem(10)}rem;
  }
`

const Card = styled.div`
  border-radius: 8px;
  background: ${props => props.backgroundColor};
  box-shadow: 6px 6px 0 ${props => props.boxShadow};
  box-sizing: border-box;
  transition: all 0.3s ease;

  padding: ${rem(50)}rem ${rem(15)}rem;
  margin: ${rem(30)}rem 0;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 1100px) {
    width: ${rem(300)}rem;
    margin: 0 ${rem(30)}rem 0 0;
    padding: ${rem(50)}rem ${rem(0)}rem;
    &:last-child {
      margin: 0;
    }
  }
`

const CardText = styled.p`
  color: ${props => (props.color ? props.color : "#000")};
  line-height: ${props =>
    props.lineHeight ? `${rem(props.lineHeight)}rem` : `${rem(24)}rem`};
  text-align: center;
  opacity: 0.7;
  padding: 0 ${rem(15)}rem;
  text-align: center;
  font-size: ${rem(14)}rem;
  font-weight: 500;
`

const Ask = () => {
  return (
    <Container>
      <CommitmentContent>
        <SectionTitle>Our Ask</SectionTitle>
        <CardRow>
          <MobileOnlyCarousel>
            {askData.map((item, idx) => (
              <Card
                backgroundColor={item.backgroundColor}
                boxShadow={item.boxShadow}
                key={idx}
              >
                <CardText>{item.text}</CardText>
              </Card>
            ))}
          </MobileOnlyCarousel>
        </CardRow>
      </CommitmentContent>
    </Container>
  )
}

export default Ask
